import CallToActionTypeForm from "@/components/CallToActionTypeForm";
import { Destiny } from "@/components/Destiny/Destiny";
import Lead from "@/components/Modals/Lead";
import Video from "@/components/Player/Video";
import VTurb from "@/components/Player/VTurb";
import { Header } from "../Header/Header";

export const FirstPage = ({ vsl = false }) => {
  return (
    <section
      id="home"
      className={
        "max-w-screen bg-black/75 bg-cover bg-no-repeat bg-[url('/maldivas.webp')]"
      }
    >
      <Header />

      <div
        className={
          "flex flex-col items-center gap-4 md:gap-8 p-3 container mx-auto text-center text-white"
        }
        data-aos="fade-up"
      >
        <h1
          className={
            "text-2xl md:text-3xl font-bold max-w-4xl md:leading-[42px]"
          }
        >
          Se você gasta{" "}
          <span className={"bg-white text-black"}>acima de 20 mil por mês</span>{" "}
          ou viaja mais de 2x ao ano, assista o vídeo abaixo!
        </h1>

        <span className={"text-sm md:text-xl font-light"}>
          Gerenciamos suas milhas para que você economize até 50% em suas
          viagens.
        </span>
        {vsl ? (
          <div className={"w-full max-w-4xl"}>
            <VTurb
              video={"99583553-0c7c-40d5-b819-534dcd7867b9"}
              player={"6671c529b4e582000bbc1391"}
            />
            <CallToActionTypeForm text={"QUERO APLICAR AGORA"} />
            <Lead
              eventId={Date.now().toString()}
              redirectTo={"https://www.flyprime.com.br/obrigado"}
              pagina={"VSL02"}
              value={700000}
            />
          </div>
        ) : (
          <div className="max-w-4xl w-full aspect-video box-glow rounded-2xl">
            <Video id={"XUx4UVSXIXE"} />
          </div>
        )}
      </div>

      {!vsl && <Destiny />}
    </section>
  );
};
